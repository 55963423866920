import axios from 'axios';

const state = {
  serverStatus: false,
}

const mutations = {
  GET_STATUS: (state, payload) => {
    state.serverStatus = payload;
  },
}

const actions = {
  getStatus: (context) => {
    let promise = new Promise((resolve, reject) => {
      axios.get('/')
        .then(response => {
          context.commit('GET_STATUS', true);
          resolve(response.data);
        })
        .catch(error => {
          context.commit('GET_STATUS', false);
          reject(error.response);
        })
    })
    return promise;
  },
  sendEmail: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/contato`, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    })
    return promise;
  }
}

export default {
  state,
  mutations,
  actions
}